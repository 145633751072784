<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    
      <b-container fluid class="mt--6">
        <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator"> 
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(userFormSubmit)"
              novalidate
              > 
            <b-row>
              <b-col md="3">
              <base-input :disabled="isUpdate()"
                      type="text"
                      :name="'Name'"
                      label="Name"
                      :required="true"
                      placeholder="Name"
                      v-model="userForm.name"
                    >
                    </base-input>
                    </b-col>
            </b-row>
           

                <!-- <b-row> 
                  <b-col  v-for="(objValue, key) in userForm.permissions" :key="key" md="3">
                  <fieldset>
                    <legend>{{ key }}</legend>
                    <div v-for="(innerValue, innerKey) in objValue" :key="innerKey" for="edit_cms" >
                    <label  :for="innerKey">{{ innerKey }}</label>
                    <input
                      type="checkbox"
                      :id="innerKey + '_' + key"
                      :name="innerKey + '_' + key"
                      value="edit"
                    />
                   
                  </div>
                  </fieldset>
                  </b-col>  
                </b-row> -->

                <table>
                      <tr>
                        <th class="left">Entity</th>
                        <th>View</th>
                        <th>Publish</th>
                        <th>Edit</th>
                        <th>Delete</th>

                      </tr>
                      <tr v-for="(p, key) in userForm.permissions" :key="key">
                        <td class="left">{{key}}</td>
                        <td><input type="checkbox" :disabled="shouldDisable(p)" @change="removeView(p)" v-if="p.view !== undefined" v-model="p.view" /></td>
                        <td><input type="checkbox"  @change="addView(p)" v-if="p.publish !== undefined" v-model="p.publish" /></td>
                        <td><input type="checkbox" @change="addView(p)" v-if="p.edit !== undefined" v-model="p.edit" /></td>
                        <td><input type="checkbox" @change="addView(p)"  v-if="p.delete !== undefined" v-model="p.delete" /></td>

                      </tr>

                    </table>


              <base-button
               
                type="primary"
                native-type="submit"
                class="my-4"
              >
                {{ isUpdate()? 'Update' : 'Create' }}
              </base-button>

              
            </b-form>
            
          </validation-observer>
        

          
        </b-card>
         </b-overlay>
      </b-container>
   
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as userRepo from "@/api-repo/user";
import { fullname } from "@/util/fullname";
import { capitalize } from "@/util/capitalize";
import * as roleRepo from '@/api-repo/roles'


import { handleError, successMessage } from "@/util/error-handler";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
  
  },
  data() {
    return {
      userForm: {
        name: '',
        permissions:{ 
        cms: {
          edit: false,
          view: false,
          delete: false,
          publish:false,
        },
        users: {
          edit: false,
          view: false,
          delete: false,
        },
        media: {
          edit: false,
          view: false,
          delete: false,
        },
        folder: {
          edit: false,
          view: false,
          delete: false,
        },
        roles: {
          edit: false,
          view: false,
          delete: false,
        },
        personnel: {
          edit: false,
          view: false,
          delete: false,
        },
        guest: {
          edit: false,
          view: false,
          delete: false,
        },
        stay: {
          edit: false,
          view: false,
          delete: false,
        },
        medical_records: {
          edit: false,
          view: false,
          delete: false,
        },
        dashboard: {
          view: false,
        },
      }
      },
      isBusy: false,
    };
  },
  created() {
    if(!this.isUpdate()){
      return;
    }
    this.getData();
  },
  methods: {
    generateBreadCrumb() {
      return [{label: "Roles", route: "Roles"},{label: this.isUpdate() ? "Edit Role"  : "Add New Role"}];
    },
    
    getData: async function () {
      try {
        this.isBusy = true;
        let result = await roleRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
        this.userForm.name = result.data.data.name;
        for(let parentKeyObj in this.userForm.permissions){
          for(let key in this.userForm.permissions[parentKeyObj]){
           this.userForm.permissions[parentKeyObj][key] = result.data.data.permissions[parentKeyObj] ? result.data.data.permissions[parentKeyObj][key] : false
          }
    
        }
        

      } catch (e) {
        console.log(e)
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },


    shouldDisable(p) {
      if (p.edit == true || p.add == true || p.delete == true) {
        return true;
      }
    },

    addView(p) {
      if (p.edit == true || p.add == true || p.delete == true) {
        if (p.view !== undefined) {
          p.view = true;
        }
      }
    },

    removeView(p) {
      if (p.edit == true || p.add == true || p.delete == true) {
        if (p.view !== undefined) {
          p.view = true;
        }
      }
    },

    isUpdate: function () {
      return this.$route.params.id != null;
    },
    userFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
             await roleRepo.create(
              this.userForm,
              this.userStore.state.token
            );
            successMessage("User created successfully");
            this.$router.replace({ path: "/security/roles" });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
             await roleRepo.update(
              this.$route.params.id,
              this.userForm,
              this.userStore.state.token
            );
            successMessage("User updated successfully");
            
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },

  },
};
</script>



<style scoped>
table {
  width:100%;

}
table th {
  text-align:center;
  padding:5px;
  border:1px solid #ccc;
}
table td {
  text-align:center;
  padding:5px;
  border:1px solid #ccc;
}
.left {
  text-align:left
}
tr:nth-child(even) {background-color: #f2f2f2;}


select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}

select:hover {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}
select:hover::-ms-expand {
  display: block;
}



</style>

 