<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">

          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button @click="newRole()" size="sm" type="neutral"><i class="mr-1 fas fa-plus" />Add Role</base-button>
        </b-col>
      </b-row>
    </base-header>
  <b-container fluid class="mt--6">
    <b-row>
      <b-col >
        <b-card no-body>
          <b-card-header class="border-0">
            <b-row>
              <b-col >
              </b-col>
              <b-col cols="3">
              <form>
                <b-form-group class="mb-0">
                  <b-input-group >
                    <b-input-group-prepend is-text>

                    </b-input-group-prepend>

                  </b-input-group>
                </b-form-group>

              </form>
          </b-col>
          </b-row>
          </b-card-header>

          <b-table class=" w-100"
            header-row-class-name="thead-light"
            :busy.sync="isBusy"
            :filter="search"
            :items="provider"
            :fields="fields"
            >
            <template #cell(actions)="row">
              <router-link :to="'/security/roles/' + row.item._id" class="p-2"><i class="fa-lg fas fa-arrow-right"></i></router-link>
            </template>
          
        </b-table>
 
    </b-card>
  </b-col>
</b-row>
</b-container>
</div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as roleRepo from "@/api-repo/roles";

export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
  },
  data() {
    return {
      isBusy: false,

      rows: [],
      search: null,
      fields: [
        { key: "name", title: "Name" },
        { key: "actions", title: "" },
      ],
    };
  },
  methods: {
    generateBreadCrumb() {
      return [{ label: "Roles" }];
    },

    newRole: function () {
      this.$router.push({ path: "/security/roles/new" });
    },
    provider: async function () {
      this.isBusy = true;
      let result = await roleRepo.getAll(this.userStore.state.token);
      this.isBusy = false;
      return result.data.data;
    },
  },
};
</script>


      


     